/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {HTTP_INTERCEPTORS, HttpBackend, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NgxPiDsModule} from '@pitagon/ngx-pids';
import {AuthenticationGuard, ErrorInterceptor} from "@pitagon/ngx-pids/security";
import {GeneralComponent} from "@pitagon/ngx-pids/utils";
import {MultiTranslateHttpLoader} from "ngx-translate-multi-http-loader";
import {AppLayoutModule} from './app-layout/';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AgmCoreModule} from "@agm/core";
import {BreadcrumbsModule, PageLayoutModule} from "@pitagon/ngx-pids/components";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatMenuModule} from "@angular/material/menu";
import {MatTableModule} from "@angular/material/table";

@NgModule({
  declarations: [AppComponent, GeneralComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpBackend]
      }
    }),

    // NgxPiDS
    NgxPiDsModule,
    AppLayoutModule,
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCsLs4_aoIfLI5zqAWddpyCWh5bYp7nSds'
    }),
    BreadcrumbsModule,
    PageLayoutModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTableModule
  ],
  providers: [
    AuthenticationGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// AOT compilation support
export function httpTranslateLoader(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend, [
    'assets/i18n/ngx-pids/',
    'assets/i18n/app/',
  ]);
}
