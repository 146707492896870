/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const DEFAULT_REMOTE_API = {
  auth: {
    login: '/auth/login',
    changePasswordFirstLogin: '/auth/change-password-first-login',
    forgotPassword: '/auth/forgot-password',
    forgotPasswordVerifyOtp: '/auth/forgot-password/verify-otp',
    forgotPasswordByPhoneNumber: '/auth/forgot-password/phone-number',
    forgotPasswordByEmail: '/auth/forgot-password/email',
    resetPassword: '/auth/reset-password',
    checkTimeResetPassword: '/auth/check-time-reset-password',
  },
}
