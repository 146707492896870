/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ConfigService} from "@pitagon/ngx-pids/config";
import {
  ChangePasswordFirstLoginForm,
  CheckTimeResetPasswordForm,
  LoginForm,
  ResetPasswordByEmailForm,
  ResetPasswordByPhoneNumberForm,
  ResetPasswordForm,
  VerifyOtpForm
} from "@pitagon/ngx-pids/pages";
import {LoginResponse} from "./login.response";
import {environment} from "../../../../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({"Content-Type": "application/json"}),
};

@Injectable({
  providedIn: "root",
})
export class AuthenticationApi {

  remoteApi;

  constructor(private httpClient: HttpClient,
              private configService: ConfigService) {
    this.remoteApi = configService.currentConfig.remoteApi;
  }

  public login(form: LoginForm): Promise<LoginResponse> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<LoginResponse>(environment.apiUrl + this.remoteApi.auth.login, form, httpOptions)
        .subscribe((response: LoginResponse) => {
          resolve(response);
        }, reject);
    });
  }

  public changePasswordFirstLogin(form: ChangePasswordFirstLoginForm): Promise<LoginResponse> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<LoginResponse>(environment.apiUrl + this.remoteApi.auth.changePasswordFirstLogin, form, httpOptions)
        .subscribe((response: LoginResponse) => {
          resolve(response);
        }, reject);
    });
  }

  public forgotPassword(form: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(environment.apiUrl + this.remoteApi.auth.forgotPassword, form, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  public resetPassword(form: ResetPasswordForm): Promise<LoginResponse> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<LoginResponse>(environment.apiUrl + this.remoteApi.auth.resetPassword, form, httpOptions)
        .subscribe((response: LoginResponse) => {
          resolve(response);
        }, reject);
    });
  }

  public checkTimeResetPassword(form: CheckTimeResetPasswordForm): Promise<LoginResponse> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<LoginResponse>(environment.apiUrl + this.remoteApi.auth.checkTimeResetPassword, form, httpOptions)
        .subscribe((response: LoginResponse) => {
          resolve(response);
        }, reject);
    });
  }

  public verifyOtp(form: VerifyOtpForm): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(environment.apiUrl + this.remoteApi.auth.forgotPasswordVerifyOtp, form, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }


  public resetPasswordByEmail(form: ResetPasswordByEmailForm): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(environment.apiUrl + this.remoteApi.auth.forgotPasswordByEmail, form, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  public resetPasswordByPhoneNumber(form: ResetPasswordByPhoneNumberForm): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(environment.apiUrl + this.remoteApi.auth.forgotPasswordByPhoneNumber, form, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
