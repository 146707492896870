/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Platform} from '@angular/cdk/platform';
import {DOCUMENT} from '@angular/common';
import {Component, Inject, LOCALE_ID, Renderer2} from '@angular/core';
import {MatIconRegistry, SafeResourceUrlWithIconOptions} from '@angular/material/icon';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  ColorSchemeName,
  ConfigService,
  DEFAULT_COUNTRY_LANGUAGE_DATASOURCE,
  DEFAULT_REMOTE_API,
  DEFAULT_ROUTE_URL,
  NgxPiDsConfigName
} from '@pitagon/ngx-pids/config';
import {ColorVariable, colorVariables, DefaultOperationType, DefaultResourceType} from '@pitagon/ngx-pids/interfaces';
import {
  CountryLanguageService,
  LayoutService,
  NavigationService,
  SplashScreenService
} from '@pitagon/ngx-pids/services';
import {mergeDeep} from "@pitagon/ngx-pids/utils";
import * as _ from "lodash";
import {Settings} from 'luxon';
import {AuthenticationService} from "@pitagon/ngx-pids/security";
import {environment} from '../environments/environment';
import {AppOperationType, AppResourceType, COUNTRY_LANGUAGE_DATASOURCE, REMOTE_API, ROUTE_URL} from "./config";

@Component({
  selector: 'ngx-pids-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private translate: TranslateService,
              private configService: ConfigService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService,
              private countryLanguageService: CountryLanguageService,
              private readonly matIconRegistry: MatIconRegistry,
              private readonly domSanitizer: DomSanitizer,
              private authenticationService: AuthenticationService) {
    Settings.defaultLocale = this.localeId;
    const _languages = _.uniqBy([...DEFAULT_COUNTRY_LANGUAGE_DATASOURCE, ...COUNTRY_LANGUAGE_DATASOURCE], 'languageCode');
    const _defaultLanguage = _languages.find(item => item.languageCode === environment.defaultLanguage);
    countryLanguageService.currentCountryLanguage = _defaultLanguage;
    configService.updateConfig({
      i18n: {
        languages: _languages,
        defaultLanguage: _defaultLanguage,
      },
      security: {
        auth: {
          operations: Object.values({...DefaultOperationType, ...AppOperationType}),
          resources: Object.values({...DefaultResourceType, ...AppResourceType}),
        }
      },
      remoteApi: mergeDeep(DEFAULT_REMOTE_API, REMOTE_API),
      route: mergeDeep(DEFAULT_ROUTE_URL, ROUTE_URL),
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/images/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/images/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/images/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=pids&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as NgxPiDsConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });

    /**
     * Add your own routes here
     */
    this.navigationService.items = [
      {
        type: 'link',
        label: 'Thống kê',
        route: '/',
        icon: 'mat:insights',
        routerLinkActiveOptions: {exact: true}
      },
      {
        type: 'subheading',
        label: 'Apps',
        children: [
          {
            type: 'dropdown',
            label: 'Management',
            icon: 'mat:assignment',
            children: [
              {
                type: 'link',
                label: 'Users',
                route: 'apps/management/users'
              },
            ]
          },
          // {
          //   type: 'dropdown',
          //   label: 'Giám sát',
          //   icon: 'mat:filter_center_focus',
          //   children: [
          //     {
          //       type: 'link',
          //       label: 'Bản đồ',
          //       route: '/apps/monitoring'
          //     },
          //     {
          //       type: 'link',
          //       label: 'Thiết bị',
          //       route: '/apps/device-monitoring'
          //     },
          //   ]
          // },{
          //   type: 'dropdown',
          //   label: 'Quản lý sự cố',
          //   icon: 'mat:warning',
          //   children: [
          //     {
          //       type: 'link',
          //       label: 'Cảnh báo',
          //       route: 'apps/incident-management/alert'
          //     },{
          //       type: 'link',
          //       label: 'Sổ báo cáo sự cố',
          //       route: 'apps/incident-management/incident-report-book'
          //     },{
          //       type: 'link',
          //       label: 'Lệnh điều động',
          //       route: 'apps/incident-management/maneuvering-command'
          //     },{
          //       type: 'link',
          //       label: 'Biên bản bàn giao',
          //       route: 'apps/incident-management/minutes-of-handover-record'
          //     },
          //   ]
          // },{
          //   type: 'dropdown',
          //   label: 'Đơn vị',
          //   icon: 'mat:domain',
          //   children: [
          //     {
          //       type: 'link',
          //       label: 'Cơ sở kinh doanh',
          //       route: 'link#'
          //     },{
          //       type: 'link',
          //       label: 'Đơn vị PCCC',
          //       route: 'apps/tenant-management'
          //     },{
          //       type: 'link',
          //       label: 'Đơn vị phối hợp',
          //       route: 'link#'
          //     }
          //   ]
          // },
          {
            type: 'dropdown',
            label: 'Giám sát',
            icon: 'mat:filter_center_focus',
            children: [
              {
                type: 'link',
                label: 'Comcat',
                route: '/apps/com-cats'
              },
              {
                type: 'link',
                label: 'Bản đồ',
                route: '/apps/monitoring'
              },
              {
                type: 'link',
                label: 'Thiết bị',
                route: '/apps/device-monitoring'
              },
              {
                type: 'link',
                label: 'Model thiết bị',
                route: '/apps/device-model-monitoring'
              },
              {
                type: 'link',
                label: 'Lịch sử tác động',
                route: '/apps/activity-log'
              },
            ]
          },{
            type: 'dropdown',
            label: 'Quản lý sự cố',
            icon: 'mat:warning',
            children: [
              {
                type: 'link',
                label: 'Cảnh báo',
                route: 'apps/incident-management/alert'
              },{
                type: 'link',
                label: 'Sổ báo cáo sự cố',
                route: 'apps/incident-management/incident-report-book'
              },{
                type: 'link',
                label: 'Lệnh điều động',
                route: 'apps/incident-management/maneuvering-command'
              },{
                type: 'link',
                label: 'Biên bản bàn giao',
                route: 'apps/incident-management/minutes-of-handover-record'
              },
            ]
          },{
            type: 'dropdown',
            label: 'Đơn vị',
            icon: 'mat:domain',
            children: [
              {
                type: 'link',
                label: 'Cơ sở kinh doanh',
                route: 'link#'
              },{
                type: 'link',
                label: 'Đơn vị PCCC',
                route: 'apps/tenant-management'
              },{
                type: 'link',
                label: 'Đơn vị phối hợp',
                route: 'link#'
              }
            ]
          }, {
            type: 'link',
            label: 'Nhân lực',
            route: 'apps/human-resource',
            icon: 'mat:people'
          },
          {
            type: 'link',
            label: 'Roles',
            route: 'apps/new-role'
          },
      {
            type: 'dropdown',
            label: 'Tài lực',
            icon: 'mat:payment',
            children:[
              {
                type: 'link',
                label: 'Phương tiện',
                route: 'apps/vehicle'
              },{
                type: 'link',
                label: 'Nguồn nước',
                route: 'apps/water-resource'
              }
            ]
          },
          // {
          //   type: 'link',
          //   label: 'Cài đặt',
          //   route: '#',
          //   icon: 'mat:settings'
          // },{
          //   type: 'link',
          //   label: 'Hoạt động',
          //   route: '#',
          //   icon: 'mat:history'
          // },{
          //   type: 'link',
          //   label: 'Về chúng tôi',
          //   route: '#',
          //   icon: 'mat:supervisor_account'
          // },{
          //   type: 'link',
          //   label: 'Hỗ trợ',
          //   route: '#',
          //   icon: 'mat:headset_mic'
          // },
          // {
          //   type: 'link',
          //   label: 'Roles',
          //   route: '/apps/role',
          //   icon: 'security'
          // },
        ]
      }
    ];
  }
}
