/*
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {DEFAULT_ENVIRONMENT} from "./environment.default";

export const environment = {
  ...DEFAULT_ENVIRONMENT,
  production: true,
  appUrl: 'http://admin.dev.pisafe.vn',
  apiUrl: 'http://192.168.5.3:9008/api/admin',
  mediaStaticUrl: 'https://static.dev.pisafe.vn',

  host: 'http://192.168.5.3',
  base_api: '/api/admin',
  port: 9008,
  access_control_allow_origin: 'http://admin.dev.pisafe.vn',
  host_static_img: ''
};
